import { Router } from '@vaadin/router';
import { BoostWebsiteAboutUs } from '../pages/BoostWebsiteAboutUs';
import { BoostWebsiteContactRequestSuccess } from '../pages/BoostWebsiteContactRequestSuccess';
import { BoostWebsiteFrontPage } from '../pages/BoostWebsiteFrontPage';
import { BoostWebsiteContact } from '../pages/BoostWebsiteContact';
import { BoostWebsitePrivacyPolicy } from '../pages/BoostWebsitePrivacyPolicy';
export const router = new Router();
router.setRoutes([
    {
        path: '/',
        component: BoostWebsiteFrontPage.is,
    },
    {
        path: '/contact',
        component: BoostWebsiteContact.is,
    },
    {
        path: '/about',
        component: BoostWebsiteAboutUs.is,
    },
    {
        path: '/success',
        component: BoostWebsiteContactRequestSuccess.is,
    },
    {
        path: '/privacypolicy',
        component: BoostWebsitePrivacyPolicy.is,
    },
]);
