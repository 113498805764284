import { html } from 'lit';
export const bookingIcon = html `<svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    width="24"
    height="24"
    color="#000000"
>
    <defs>
        <style>
            .cls-637a534e15c575900940073e-1 {
                fill: none;
                stroke: currentColor;
                stroke-miterlimit: 10;
            }
        </style>
    </defs>
    <rect class="cls-637a534e15c575900940073e-1" x="1.48" y="3.37" width="21.04" height="4.78"></rect>
    <rect class="cls-637a534e15c575900940073e-1" x="1.48" y="8.15" width="21.04" height="14.35"></rect>
    <line class="cls-637a534e15c575900940073e-1" x1="3.39" y1="14.85" x2="5.3" y2="14.85"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="5.3" y1="11.98" x2="5.3" y2="17.72"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="18.7" y1="11.98" x2="18.7" y2="17.72"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="15.83" y1="11.02" x2="15.83" y2="18.67"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="8.17" y1="11.02" x2="8.17" y2="18.67"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="8.17" y1="14.85" x2="15.83" y2="14.85"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="18.7" y1="14.85" x2="20.61" y2="14.85"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="12" y1="0.5" x2="12" y2="5.28"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="6.26" y1="0.5" x2="6.26" y2="5.28"></line>
    <line class="cls-637a534e15c575900940073e-1" x1="17.74" y1="0.5" x2="17.74" y2="5.28"></line>
</svg>`;
