var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostWebsiteImageSuccess = class BoostWebsiteImageSuccess extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-image-success';
    }
    static get styles() {
        return css ``;
    }
    /** @override */ render() {
        return html `
            <div style="display:flex; justify-content:center">
                <svg
                    style="z-index:2;"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                    width="${!this.smallScreen ? 400 : 150}"
                    height="${!this.smallScreen ? 450 : 150}"
                    viewBox="0 0 816.21532 621"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <path
                        d="M518.50083,268.28111h-2.97759V186.71075A47.2107,47.2107,0,0,0,468.31258,139.5H295.49464a47.2107,47.2107,0,0,0-47.21079,47.2106V634.21209a47.2107,47.2107,0,0,0,47.21065,47.21075H468.31235a47.21071,47.21071,0,0,0,47.21084-47.21057v-307.868h2.97764Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M470.21737,151.7826H447.65892A16.75027,16.75027,0,0,1,432.15047,174.859H333.14532a16.75024,16.75024,0,0,1-15.50845-23.0764H296.56722A35.25642,35.25642,0,0,0,261.31077,187.039V633.88382a35.25643,35.25643,0,0,0,35.25641,35.25646H470.21737a35.25643,35.25643,0,0,0,35.25645-35.25642h0V187.039A35.2564,35.2564,0,0,0,470.21737,151.7826Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#fff"
                    />
                    <circle
                        id="baf12095-0797-4180-a98d-6422936d747a"
                        data-name="Ellipse 44"
                        cx="191.5"
                        cy="261.2336"
                        r="84.4462"
                        fill="#4cf76b"
                    />
                    <path
                        id="b582f34e-fd02-4670-b1d8-d23f069ed737-213"
                        data-name="Path 395"
                        d="M375.02417,432.94733a8.75832,8.75832,0,0,1-5.26905-1.75113l-.09426-.07069-19.846-15.18155a8.81941,8.81941,0,1,1,10.73182-13.99843l12.85463,9.85753L403.77751,372.174a8.81571,8.81571,0,0,1,12.36-1.63259l.00256.00192-.18852.26177.19363-.26177a8.82633,8.82633,0,0,1,1.63067,12.36249l-35.729,46.5917a8.821,8.821,0,0,1-7.01492,3.4394Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#fff"
                    />

                    <path
                        d="M965.062,236.135a14.65213,14.65213,0,0,0-28.13652-7.42622l-30.91721,35.44433,9.07157,25.33678,31.093-41.4153A14.573,14.573,0,0,0,965.062,236.135Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#ffb7b7"
                    />
                    <polygon
                        points="645.013 234.549 747.642 125.02 733.799 103.012 605.076 211.876 645.013 234.549"
                        fill="#3f3d56"
                    />
                    <circle cx="622.10766" cy="132" r="36" fill="#2f2e41" />
                    <path
                        d="M816.8938,251.16321A75.48534,75.48534,0,0,1,844.35693,233.404c6.63868-2.45941,13.86451-3.97894,20.80494-2.58221,6.94067,1.39666,13.50415,6.19806,15.44042,13.00775,1.58326,5.56836-.05151,11.56378-2.50878,16.80554-2.457,5.24176-5.73755,10.10248-7.72461,15.53986a35.468,35.468,0,0,0,35.68921,47.56231c-6.81934.91437-13.10547,4.119-19.771,5.82483-6.66553,1.70575-14.53272,1.59009-19.48608-3.18524-5.241-5.05243-5.346-13.26715-5.09253-20.54248q.56507-16.22241,1.13012-32.44495c.19214-5.51544.3562-11.20764-1.63305-16.35553-1.989-5.14788-6.71607-9.6557-12.23462-9.60882-4.18262.03546-7.88452,2.56927-11.23853,5.068-3.35449,2.49878-6.90454,5.16474-11.0708,5.53638-4.166.3717-8.92285-2.71143-8.61108-6.88245Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M677.26948,513.22286a14.65213,14.65213,0,0,1-11.0391-26.92491l31.10052-35.28358,26.30491,5.68307-36.99733,36.23807a14.573,14.573,0,0,1-9.36894,20.28732Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#ffb7b7"
                    />
                    <polygon
                        points="587.698 247.405 510.183 344.705 486.555 333.857 577.665 192.012 587.698 247.405"
                        fill="#3f3d56"
                    />
                    <polygon
                        points="525.848 541.211 507.374 540.921 509.596 603.887 525.845 603.887 525.848 541.211"
                        fill="#ffb7b7"
                    />
                    <path
                        d="M721.8823,738.08194l-32.00113-.00128h-.00131A20.39476,20.39476,0,0,0,669.48624,758.474v.66272l52.39508.00195Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#2f2e41"
                    />
                    <polygon
                        points="648.165 524.511 627.984 537.467 668.352 586.032 682.026 577.253 648.165 524.511"
                        fill="#ffb7b7"
                    />
                    <path
                        d="M874.53983,710.04911,847.61075,727.3378l-.00109.00067a20.39471,20.39471,0,0,0-6.14264,28.179l.358.55768,44.09051-28.3067Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M847.24693,471.74009s-44.83957-148.9274-59.24978-150.93993-37.62808,38.21305-37.62808,38.21305c-30.103,38.40821-61.144,162.82087-61.144,162.82087s51.74659,3.23809,89.57339,23.36344,82.46764-1.0188,82.46764-1.0188Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M847.24693,471.74009s-44.83957-148.9274-59.24978-150.93993-31.73106,38.62126-31.73106,38.62126c-15,62-67.041,162.41266-67.041,162.41266s51.74659,3.23809,89.57339,23.36344,82.46764-1.0188,82.46764-1.0188Z"
                        transform="translate(-191.89234 -139.5)"
                        opacity="0.1"
                        style="isolation:isolate"
                    />
                    <polygon
                        points="618.567 287.682 618.567 362.517 627.374 470.921 671.374 552.921 650.374 567.921 590.374 489.921 566.643 412.472 529.374 583.921 501.374 581.921 520.82 344.21 557.721 277.3 618.567 287.682"
                        fill="#2f2e41"
                    />
                    <path
                        d="M765.24985,354.79968c-14,14-1,29-1,29l-16,36,63,17,22.01624-98.37826-15-16c-30-1-30.01624-1.62174-30.01624-1.62174C782.99363,325.94351,765.24985,354.79968,765.24985,354.79968Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#28e04d"
                    />
                    <path
                        d="M835.8032,328.22953a26.8839,26.8839,0,0,0-37.77734,5.91308c-15.83789,22.05225-30.48242,57.66114-10.30469,102.07178,32.39893,71.30957,60.59326,107.81445,72.05518,107.81055a4.55981,4.55981,0,0,0,1.47314-.23145c7.05518-2.39355,17.25586-14.332,19.10254-24.85254.66113-3.76562.50391-9.01074-3.99951-12.6123-12.93555-10.34619-36.15772-42.56152-43.40186-84.96387-4.00341-23.42969,3.76026-44.772,10.978-58.5498a26.87922,26.87922,0,0,0-7.77734-34.3291h0Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#3f3d56"
                    />
                    <circle
                        cx="803.14042"
                        cy="280.71068"
                        r="26.50012"
                        transform="translate(-228.11672 280.13426) rotate(-28.6632)"
                        fill="#ffb7b7"
                    />
                    <path
                        d="M765.872,278.70709h38.26556l.39252-5.49379,1.96191,5.49379h5.89209l.77759-10.88853,3.88855,10.88853h11.40088v-.53947a28.62427,28.62427,0,0,0-28.59192-28.59215h-5.395A28.62448,28.62448,0,0,0,765.872,278.16762Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M822.67028,298.45432,839,293.1556V253.5H808.15564l.76361.76243C819.54388,264.87334,831.36033,302.51949,822.67028,298.45432Z"
                        transform="translate(-191.89234 -139.5)"
                        fill="#2f2e41"
                    />
                </svg>
            </div>
        `;
    }
};
BoostWebsiteImageSuccess._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteImageSuccess.prototype, "smallScreen", void 0);
BoostWebsiteImageSuccess = __decorate([
    customElement(BoostWebsiteImageSuccess.is)
], BoostWebsiteImageSuccess);
export { BoostWebsiteImageSuccess };
