import { html } from 'lit';
export const communicationIcon = html `
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        width="24"
        height="24"
        color="#000000"
    >
        <defs>
            <style>
                .cls-63ce749bea57ea6c8380061e-1 {
                    fill: none;
                    stroke: currentColor;
                    stroke-miterlimit: 10;
                }
            </style>
        </defs>
        <circle class="cls-63ce749bea57ea6c8380061e-1" cx="19.65" cy="16.8" r="1.91"></circle>
        <path
            class="cls-63ce749bea57ea6c8380061e-1"
            d="M1.48,23.5a2.87,2.87,0,0,1,2.87-2.87h0A2.88,2.88,0,0,1,7.22,23.5"
        ></path>
        <path
            class="cls-63ce749bea57ea6c8380061e-1"
            d="M16.78,23.5a2.88,2.88,0,0,1,2.87-2.87h0a2.87,2.87,0,0,1,2.87,2.87"
        ></path>
        <circle class="cls-63ce749bea57ea6c8380061e-1" cx="4.35" cy="16.8" r="1.91"></circle>
        <polyline
            class="cls-63ce749bea57ea6c8380061e-1"
            points="14.87 4.37 18.7 4.37 18.7 10.11 18.7 11.06 15.83 10.11 9.13 10.11 9.13 7.24"
        ></polyline>
        <polygon
            class="cls-63ce749bea57ea6c8380061e-1"
            points="14.87 1.5 14.87 7.24 8.17 7.24 5.3 8.2 5.3 7.24 5.3 1.5 14.87 1.5"
        ></polygon>
    </svg>
`;
