var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostWebsiteLogInIcon } from '../../website-images/BoostWebsiteLogInIcon';
import { BoostWebsiteMailIcon } from '../../website-images/BoostWebsiteMailIcon';
import { BoostWebsiteProfilePictureIcon } from '../../website-images/BoostWebsiteProfilePictureIcon';
let BoostWebsiteHeader = class BoostWebsiteHeader extends LitElement {
    static get is() {
        return 'boost-website-header';
    }
    static get styles() {
        return css `
            .navigation-container {
                display: flex;
                padding-left: 100px;
                z-index: 4;
            }

            .nav {
                padding: 40px;
                text-decoration: none;
                color: #1b3456;
                font-weight: 550;
            }

            .nav:hover {
                cursor: pointer;
                color: #394f6f;
            }

            .bar {
                display: flex;
                width: 30px;
                height: 4px;
                margin-top: 3px;
                background-color: #1b3456;
                border-radius: 6px;
            }

            .hamburger {
                z-index: 4;
                align-self: center;
                cursor: pointer;
                transition:
                    width 2s,
                    height 2s,
                    transform 2s;
            }

            img:hover {
                cursor: pointer;
                opacity: 0.8;
            }

            .close {
                position: absolute;
                right: 32px;
                top: 40px;
                width: 40px;
                height: 32px;
                border-radius: 6px;
                opacity: 0.8;
                z-index: 5;
            }

            .close:hover {
                opacity: 1;
            }

            .close:before,
            .close:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 33px;
                width: 4px;
                background-color: #1b3456;
                border-radius: 6px;
                border-radius: 2px;
            }

            .close:before {
                transform: rotate(45deg);
            }

            .close:after {
                transform: rotate(-45deg);
            }

            @media screen and (max-width: 1200px) {
                .navigation-container {
                    justify-content: space-between;
                    padding-left: 0;
                }

                .hamburger {
                    padding: 10%;
                }

                .navbar-smallscreen {
                    color: #fefefe;
                    font-size: 25px;
                    padding: 10px 0px 0px 35px;
                    background-color: #677fa0;
                    margin: 10px;
                    border-radius: 6px;
                    align-items: center;
                    display: flex;
                    box-shadow: 1px 2px 5px -2px gray;
                    font-weight: 300;
                }

                .navbar-smallscreen:hover {
                    cursor: pointer;
                    background-color: #7489a6;
                }

                .navbar-smallscreen-container {
                    overflow: hidden;
                    min-height: 100vh;
                    background: linear-gradient(
                            to left,
                            rgba(245, 245, 245, 0.9) 1%,
                            rgba(245, 245, 245, 0.99) 90%
                        ),
                        url('images/hexagonimage.png');
                    display: flex;
                    flex-direction: column;
                    z-index: 4;
                    width: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        `;
    }
    constructor() {
        super();
        this.smallScreen = false;
        this.dropdownIsVisible = false;
        this._showDropdownMenu = () => {
            return html ` <div class="navbar-smallscreen-container">
            <div
                style="display:flex; flex-direction:row;
"
            >
                <img
                    style="height: 60px; padding: 25px"
                    @click="${() => Router.go('#')}"
                    src="/images/boostlogo.png"
                    alt="LOGO"
                />
            </div>
            <div style="display:flex; flex-direction:column; padding-top:30px;">
                <div class="navbar-smallscreen" @click="${() => this._mobileViewAboutUsLink()}">
                    <boost-website-profile-picture-icon></boost-website-profile-picture-icon>
                    <p style="padding-left:15px;margin-top: 20px;">Om oss</p>
                </div>
                <div class="navbar-smallscreen" @click="${() => this._mobileViewContactLink()}">
                    <boost-website-mail-icon></boost-website-mail-icon>
                    <p style="padding-left:15px;margin-top: 20px;">Kontakt</p>
                </div>
                <div
                    class="navbar-smallscreen"
                    @click="${() => window.location.assign('https://cerum.no/login/boost')}"
                >
                    <boost-website-log-in-icon></boost-website-log-in-icon>
                    <p style="padding-left:15px;margin-top: 20px;">Logg inn</p>
                </div>
            </div>
        </div>`;
        };
        this._dropdownMenuChanged = () => {
            this.dropdownIsVisible = !this.dropdownIsVisible;
            if (this.smallScreen && document.body.classList.contains('overflow-hidden')) {
                document.body.classList.remove('overflow-hidden');
            }
            else {
                document.body.classList.add('overflow-hidden');
            }
        };
        window.addEventListener('resize', () => this._sizeHander());
        this._sizeHander();
    }
    /** @override */ render() {
        return html `
            <div class="navigation-container">
                <img
                    style=${!this.smallScreen
            ? 'height: 80px; padding: 25px; margin-right:100px'
            : 'height: 70px; padding: 25px; margin-right:100px'}
                    @click="${() => Router.go('#')}"
                    src="/images/boostlogo.png"
                    alt="LOGO"
                />
                ${!this.smallScreen
            ? html `<div style="align-self:center; font-size:22px; display: inline-flex">
                              <div class="nav" @click="${() => Router.go('/about')}">Om oss</div>
                              <div class="nav" @click="${() => Router.go('/contact')}">Kontakt</div>
                              <div
                                  class="nav"
                                  @click="${() => window.location.assign('https://cerum.no/login/boost/')}"
                              >
                                  Logg inn
                              </div>
                          </div>`
            : html `<div
                              style="display: flex;flex-direction: column;align-self: center;padding-right: 35px;"
                          >
                              ${this.dropdownIsVisible
                ? html `<div class="close " @click="${this._dropdownMenuChanged}">
                                        <span class="bar1" id="bar1"></span>
                                        <span class="bar2" id="bar2"></span>
                                    </div>`
                : html `<div @click="${this._dropdownMenuChanged}" class="hamburger">
                                        <span class="bar"></span>
                                        <span class="bar"></span>
                                        <span class="bar"></span>
                                    </div>`}
                              ${this.dropdownIsVisible ? this._showDropdownMenu() : ''}
                          </div> `}</div>
            </div>
        `;
    }
    _sizeHander() {
        this.updateComplete.then(() => {
            if (this.offsetWidth < 1200 || /Mobi|Android/i.test(navigator.userAgent)) {
                this.smallScreen = true;
            }
            else {
                this.smallScreen = false;
            }
        });
    }
    _mobileViewAboutUsLink() {
        document.body.classList.remove('overflow-hidden');
        Router.go('/about');
    }
    _mobileViewContactLink() {
        document.body.classList.remove('overflow-hidden');
        Router.go('/contact');
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'smallScreen':
                    this._smallScreenObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _smallScreenObserver() {
        this.dispatchEvent(new CustomEvent('screen-size-changed', {
            detail: { value: this.smallScreen },
            bubbles: true,
            composed: true,
        }));
    }
};
BoostWebsiteHeader._deps = [BoostWebsiteMailIcon, BoostWebsiteProfilePictureIcon, BoostWebsiteLogInIcon];
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteHeader.prototype, "smallScreen", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteHeader.prototype, "dropdownIsVisible", void 0);
BoostWebsiteHeader = __decorate([
    customElement(BoostWebsiteHeader.is),
    __metadata("design:paramtypes", [])
], BoostWebsiteHeader);
export { BoostWebsiteHeader };
