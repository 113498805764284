var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostWebsiteMailIcon = class BoostWebsiteMailIcon extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-mail-icon';
    }
    static get styles() {
        return css `
            @media screen and (max-width: 600px) {
                #smallscreen-image {
                    position: absolute;
                    opacity: 0.2;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 612"
                width="22"
                height="25"
                style="padding-top:10px;"
            >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                    d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
                    fill="#132c4f"
                />
            </svg>
        `;
    }
};
BoostWebsiteMailIcon._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteMailIcon.prototype, "smallScreen", void 0);
BoostWebsiteMailIcon = __decorate([
    customElement(BoostWebsiteMailIcon.is)
], BoostWebsiteMailIcon);
export { BoostWebsiteMailIcon };
