var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { GoogleMap } from '../../../../libs/cerumx-shared/GoogleMap';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
import { Router } from '@vaadin/router';
import { BoostWebsiteImage } from '../../website-images/BoostWebsiteImage';
import { BoostWebsiteImageDevice } from '../../website-images/BoostWebsiteImageDevice';
import { BoostWebsiteImageSystem } from '../../website-images/BoostWebsiteImageSystem';
import { BoostImageSystem2 } from '../../website-images/BoostImageSystem2';
import { BoostWebsiteImageLocation } from '../../website-images/BoostWebsiteImageLocation';
import { BoostWebsiteImageWeb } from '../../website-images/BoostWebsiteImageWeb';
import { BoostWebsiteInformation } from '../components/BoostWebsiteInformation';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { bookingIcon } from '../../assets/images/booking';
import { checkFullDrift } from '../../assets/images/checkfulldrift';
import { communicationIcon } from '../../assets/images/communication';
import { computerIcon } from '../../assets/images/computer';
import { phoneIcon } from '../../assets/images/phone';
import { walletIcon } from '../../assets/images/wallet';
gsap.registerPlugin(ScrollTrigger);
let BoostWebsiteFrontPage = class BoostWebsiteFrontPage extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
        this.modules = [
            {
                title: 'Full drift',
                text: 'Vi utfører alle administrative oppgaver for dere.',
                icon: 'checkfulldrift',
            },
            {
                title: 'Vipps',
                text: 'Integrasjon mot Vipps, forenkler betalingen for medlemmene.',
                icon: 'phone',
            },
            {
                title: 'Booking',
                text: 'Via appen kan deres medlemmer raskt og enkelt booke seg på timer.',
                icon: 'booking',
            },
            {
                title: 'Økonomiløsning',
                text: 'Oversiktlige rapporter. Integrasjon mot purrepartner.',
                icon: 'wallet',
            },
            {
                title: 'Kommunikasjon',
                text: 'Du kan raskt og enkelt kommunisere med alle medlemmer.',
                icon: 'communication',
            },
            {
                title: 'Medlemsportal',
                text: 'Gjennom deres nettside melder medlemmene seg inn via portal.',
                icon: 'computer',
            },
        ];
    }
    static get is() {
        return 'boost-website-front-page';
    }
    static get styles() {
        return css `
            body {
                overflow: hidden;
            }
            .info-btn {
                height: 50px;
                width: 150px;
                border: none;
                border-radius: 6px;
                background-color: #122c4f;
                color: #eeeff1;
                font-size: 18px;
                cursor: pointer;
                margin-right: 15px;
                font-weight: 300;
                box-shadow: #c1c1c1 1px 2px 5px -2px;
            }

            .info-btn:hover {
                background-color: #1b3960;
            }

            .submit-btn:hover {
                background-color: #95abc7;
            }

            .submit-btn {
                height: 50px;
                width: 150px;
                border: none;
                border-radius: 6px;
                background-color: #a0b4cf;
                color: #1a3354;
                font-size: 18px;
                cursor: pointer;
                box-shadow: #c1c1c1 1px 2px 5px -2px;
            }

            h1 {
                margin: 0;
                line-height: 1.2;
                padding-top: 40px;
                padding-bottom: 10px;
                font-size: 30px;
                color: #132c4f;
                padding-left: 50px;
            }

            h1 span {
                font-size: 40px;
                color: #5ee779;
            }

            p {
                color: #677fa0;
                font-size: 18px;
                line-height: 1.4;
                padding-left: 50px;
            }

            span {
                font-size: 18px;
                color: #132c4f;
            }

            #main-container {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                height: 450px;
            }

            .top-container {
                width: 45%;
                padding-left: 100px;
                padding-top: 40px;
            }

            google-map {
                --GoogleMapsHeight: 50%;
                border-radius: 6px;
                overflow: hidden;
            }

            .second-container {
                display: flex;
                justify-content: space-evenly;
                background-color: #f6f6f6;
            }

            .co-partners-container {
                display: flex;
                justify-content: space-evenly;
                background-color: #f6f6f6;
            }

            .access-controll-container {
                display: flex;
                background-color: #f6f6f6;
                justify-content: space-evenly;
                height: 192px;
            }

            .costumer-container {
                display: flex;
                background-color: #fffdfb;
                justify-content: space-evenly;
                height: 350px;
            }

            .system-container {
                display: flex;
                background-color: #fffdfb;
                justify-content: space-evenly;
                height: 526px;
            }

            .device-container {
                display: flex;
                background-color: #d1e3ebfa;
                justify-content: space-evenly;
                height: 360px;
            }

            .module-container {
                display: flex;
                background-color: #fffdfb;
                justify-content: space-evenly;
                height: 480px;
            }

            .contact-container {
                display: flex;
                justify-content: space-evenly;
                background-color: #f6f6f6;
                height: 350px;
            }

            .web-based-container {
                display: flex;
                justify-content: space-evenly;
                background-color: #f6f6f6;
                height: 380px;
            }

            .small-container {
                width: 50%;
            }

            .img {
                opacity: 0.5;
                filter: grayscale(100%);
            }

            .img:hover {
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
                filter: grayscale(0%);
            }

            .partner-container {
                display: flex;
                justify-content: space-evenly;
                padding-top: 50px;
                padding-bottom: 40px;
                margin: auto;
                width: 60%;
                align-items: center;
            }

            .subpoint-container {
                display: flex;
                justify-content: space-evenly;
                padding-top: 40px;
            }

            .map-container {
                padding-top: 30px;
                padding-left: 25%;
                display: flex;
                padding-bottom: 30px;
            }

            .map {
                width: 600px;
                border-radius: 8px;
                overflow: hidden;
                height: 300px;
                box-shadow: 2px 5px 5px -7px gray;
            }

            .main-heading {
                line-height: 1.2;
                padding-top: 20px;
                font-size: 40px;
                color: #132c4f;
            }

            .module-text {
                width: 270px;
                display: flex;
                flex-direction: column;
                border-radius: 6px;
                padding: 16px 16px 16px 22px;
                background-color: #d1e3eb;
                height: 100px;
            }

            .grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 25px;
                grid-row-gap: 25px;
                margin-bottom: 40px;
                margin-left: auto;
                margin-right: auto;
            }

            .button-container {
                padding-left: 50px;
                padding-top: 20px;
            }

            .map-text-container {
                display: flex;
                flex-direction: column;
                align-self: center;
            }

            .map-text {
                line-height: 1.3;
                padding-left: 25px;
            }

            .map-span {
                padding-left: 25px;
            }

            .customers-container {
                display: flex;
                background-color: #fffdfb;
                justify-content: space-evenly;
            }

            .customers-img-container {
                display: flex;
                justify-content: space-evenly;
                padding-top: 50px;
                padding-bottom: 25px;
                width: 90%;
                align-self: center;
            }

            #module-heading {
                text-align: center;
                margin: 0;
                line-height: 1.2;
                padding-top: 10px;
                padding-left: 0;
                font-size: 30px;
                color: #132c4f;
            }

            #background-image-wave {
                background-image: url(images/wave-haikei-blue.png);
                background-size: cover;
                background-position-y: 9%;
                background-repeat: no-repeat;
                height: 234px;
                background-color: #f6f6f6;
            }

            #background-image-wave-down {
                background-image: url(images/wave-haikei-blue-down.png);
                background-size: cover;
                background-position-y: 90%;
                background-repeat: no-repeat;
                height: 318px;
                background-color: #f6f6f6;
            }

            .module-title {
                font-size: 20px;
            }

            .small-module-text {
                font-size: 16px;
                font-weight: normal;
                color: #213a5b;
            }

            @media screen and (max-width: 1200px) {
                #module-heading {
                    font-size: 28px;
                    text-align: left;
                    padding-left: 20px;
                }

                #main-container {
                    height: 486px;
                }

                .module-container {
                    height: 550px;
                }

                .grid {
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 3px;
                    grid-row-gap: 3px;
                }

                .module-text {
                    height: 116px;
                    padding: 4px 6px 4px 10px;
                    line-height: 1.1;
                    width: 172px;
                    line-height: 1.1;
                }

                .main-heading {
                    font-size: 35px;
                    padding-left: 20px;
                }

                h1 {
                    font-size: 28px;
                    padding-left: 20px;
                }

                h1 span {
                    font-size: 35px;
                }

                span {
                    font-size: 16px;
                }

                p {
                    font-size: 16px;
                    padding: 18px;
                    color: #293655;
                }

                .system-container {
                    height: 420px;
                }

                .top-container {
                    width: 100%;
                    padding-left: 6px;
                    padding-top: 0px;
                }

                .second-container {
                    justify-content: center;
                    padding-left: 0;
                    height: 510px;
                }

                .access-controll-container {
                    display: flex;
                    justify-content: center;
                    padding-left: 0;
                    height: 200px;
                }

                .device-container {
                    height: 430px;
                }

                .web-based-container {
                    height: 330px;
                }

                .small-container {
                    width: 98%;
                    z-index: 1;
                }

                .partner-container {
                    display: grid;

                    gap: 20px;
                    padding-top: 10px;
                }

                .co-partners-container {
                    height: 340px;
                }

                .subpoint-container {
                    display: flex;
                    flex-direction: column;
                    width: calc(80%-32%);
                    align-items: baseline;
                    padding-left: 20px;
                    padding-top: 0;
                }

                .map-container {
                    padding-left: 0;
                    display: flex;
                    padding-top: 0;
                    flex-direction: column;
                }

                .map {
                    width: 90%;
                    border-radius: 8px;
                    margin-left: auto;
                    margin-right: auto;
                    height: 180px;
                    margin-bottom: 20px;
                    box-shadow: 2px 5px 5px -7px gray;
                    padding: 10px;
                }

                .map-text-container {
                    align-self: flex-start;
                }

                .header-text-container {
                    padding-left: 0;
                }

                .button-container {
                    display: flex;
                    flex-direction: row;
                    padding-left: 15px;
                    margin-bottom: 20px;
                    width: 80%;
                }

                .info-btn {
                    width: 110px;
                    height: 46px;
                    font-size: 14px;
                }

                .submit-btn {
                    width: 110px;
                    height: 46px;
                    font-size: 14px;
                }

                .map-text {
                    line-height: 1.3;
                    padding: 0;
                    padding-left: 25px;
                    color: #8093af;
                }

                .map-span {
                    padding-left: 25px;
                    color: #293655;
                }

                .customers-container {
                    height: 400px;
                }

                .customers-img-container {
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 10px;
                    gap: 10px;
                }

                #background-image-wave-down {
                    height: 144px;
                }
                #background-image-wave {
                    height: 87px;
                    background-position-y: 8%;
                }

                .module-tite {
                    font-size: 18px;
                }

                .small-module-text {
                    font-size: 16px;
                    padding-top: 6px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div
                style="overflow: hidden; width: 100%; background: linear-gradient(to left, rgba(245,245,245,0.9) 1%, rgba(245,245,245,0.99) 90%),url('images/hexagonimage.png') ;"
            >
                <boost-website-header
                    @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
                ></boost-website-header>
                <div id="main-container">
                    <div class="top-container">
                        <h1 class="main-heading" style="margin-bottom:10px ">
                            Komplett system <br />
                            for ditt
                            <span>treningssenter</span>
                        </h1>
                        <p>
                            Boost system er markedets mest
                            <strong style="color:#293655"> moderne </strong> medlemssystem og er en
                            skreddersydd alt-i-ett løsning for ditt treningssenter.
                            <br />
                            <br />
                            Med alle funksjoner samlet på ett sted, oppnår du en svært
                            <strong style="color:#293655"> effektiv </strong> drift av ditt treningsssenter.
                            Vi tilbyr grundig opplæring, tett oppfølging og god kundestøtte.
                        </p>
                        <div class="button-container">
                            <button class="info-btn" @click="${() => Router.go('/contact')}">
                                Kom i gang
                            </button>
                            <button class="submit-btn" @click="${() => Router.go('/about')}">Om oss</button>
                        </div>
                    </div>

                    <div class="hero-image">
                        ${!this.smallScreen
            ? html `<boost-website-image style="width:50%;"></boost-website-image>`
            : html ``}
                    </div>
                </div>

                <div class="system-container ">
                    <boost-website-image-system
                        .smallScreen="${this.smallScreen}"
                    ></boost-website-image-system>

                    <div class="small-container animation-text">
                        <h1>Medlemssystem</h1>
                        <p>
                            Vårt hovedfokus er å lage løsninger som gjør dine medlemmer mest mulig
                            <strong style="color:#293655">selvgående.</strong> Sentralt i dette står vår
                            medlemsapp, med bla. "min side", elektronisk adgang, booking og nyhetsfeed.
                        </p>

                        <boost-website-information .smallScreen="${this.smallScreen}" title="Problemfri drift"
                            >Med vår populære tilleggstjeneste "Full Drift" nyter dere virkelig godt av
                            problemfri drift der vi utfører de fleste tidskrevende og administrative oppgaver
                            på vegne av dere. Dette frigir mye
                            <strong style="color:#293655">inntektsbringende</strong> tid.
                        </boost-website-information>
                        <boost-website-information .smallScreen="${this.smallScreen}" title="Full oversikt">
                            Med Boost system finner du enkelt frem til
                            <strong style="color:#293655">rapporter, statistikk og historikk.</strong> All
                            kommunikasjon blir samlet på samme sted, og du kan enkelt kommunisere med
                            medlemmene.</boost-website-information
                        >
                        <boost-website-information .smallScreen="${this.smallScreen}" title="Kjeder">
                            Alle våre løsninger er tilpasset kjeder, slik at du får
                            <strong style="color:#293655">oversikt og kontroll</strong> på tvers av senterne
                        </boost-website-information>
                    </div>
                </div>

                <div class="access-controll-container">
                    <div class="small-container ">
                        <h1>Adgangskontroll</h1>
                        <p>
                            Med Boost system kan du styre adgangen helt ned til
                            <strong style="color:#293655"> detaljnivå. </strong> Systemet er fleksibelt og kan
                            justeres etter eget ønske. Med vår medlemsapp, låser medlemmene enkelt opp
                            inngangsdøra kun ved hjelp av ett tastetrykk.
                        </p>

                        <boost-website-information .smallScreen="${this.smallScreen}" title="Tilgang">
                            Styr tilgang til senteret ut fra en rekke ulike kriterier. Du kan enkelt justere
                            åpningstider, ha ulike åpningstider på forskjellige medlemskap og avdelinger. Som
                            administrator kan du låse opp dørene fra hvor som helst.
                        </boost-website-information>
                    </div>

                    ${!this.smallScreen
            ? html `<div>
                              <boost-website-image-location></boost-website-image-location>
                          </div>`
            : html ``}
                </div>
                <div id="background-image-wave-down"></div>

                <div class="device-container">
                    <div class="boost-device-image">
                        <boost-website-image-device
                            .smallScreen="${this.smallScreen}"
                        ></boost-website-image-device>
                    </div>
                    <div class="small-container animation-text2">
                        <h1>Boost medlemsapp</h1>
                        <p style="color:#132c4f">
                            Med vår medlemsapp har dine medlemmer
                            <strong style="color:#293655"> full oversikt</strong> over sitt eget medlemskap og
                            de kan enkelt sjekke faktura, treningshistorikk og bookinger. Medlemsappen er
                            svært brukervennlig og har alt medlemmet trenger for å administrere sitt
                            medlemskap og sin treningshverdag.
                        </p>

                        <boost-website-information
                            .smallScreen="${this.smallScreen}"
                            title="Oppdaterte medlemmer"
                        >
                            <p style="color: #000; margin: 0; padding:0; color:#132c4f ">
                                Gjennom appen er medlemmene alltid oppdaterte på sitt medlemskap.
                            </p>
                        </boost-website-information>
                        <boost-website-information
                            .smallScreen="${this.smallScreen}"
                            title="Moderne og brukervennlig"
                        >
                            <p style="color: #000; margin: 0; padding:0; color:#132c4f ">
                                Medlemsappen gir medlemmene full oversikt og er enkel å ta i bruk.
                            </p>
                        </boost-website-information>
                    </div>
                </div>
                <div id="background-image-wave"></div>

                <div class="web-based-container">
                    <div class="small-container">
                        <h1 style="padding-top:0">Web-basert</h1>
                        <p>
                            Boost system er fullstendig<strong style="color:#293655"> web-basert. </strong
                            >Dette gjør at du har tilgang til systemet uansett hvor i verden du måtte befinne
                            deg. Vi ønsker at du skal bruke tid på bedriftens kjernevirksomhet, mens vi tar
                            oss av dine data.
                        </p>

                        <boost-website-information .smallScreen="${this.smallScreen}" title="Alltid oppdatert"
                            >Med vår nettbaserte løsning har du alltid tilgang til vår siste versjon av
                            programvaren.</boost-website-information
                        >
                        <boost-website-information .smallScreen="${this.smallScreen}" title="Datasikkerhet"
                            >All data blir ivaretatt profesjonelt og sikkert.</boost-website-information
                        >
                    </div>
                    ${!this.smallScreen
            ? html `<div>
                              <boost-website-image-web></boost-website-image-web>
                          </div>`
            : html ``}
                </div>

                <div class="module-container" style="flex-direction:column;">
                    <h1 id="module-heading">Andre moduler</h1>
                    <div class="grid">
                        ${this.modules.map((module) => html ` <div class="module-text">
                                    <div
                                        style="display:flex; flex-direction:row; gap: 12px;padding: 6px 0px 6px 0px; align-items:center"
                                    >
                                        ${this.renderIcons(module.icon)}
                                        <span class="module-title">${module.title}</span>
                                    </div>

                                    <span class="small-module-text">${module.text}</span>
                                </div>`)}
                    </div>
                </div>
                <div class="co-partners-container" style="flex-direction:column; padding-left:0;">
                    <h1 style="text-align:center">Våre partnere</h1>
                    <div class="partner-container">
                        <img
                            style="margin-left:-16px;"
                            class="img"
                            src="images/vippsboost.png"
                            alt="#"
                            width="${!this.smallScreen ? 190 : 135}"
                            height="${!this.smallScreen ? 70 : 50}"
                        />
                        <img
                            class="img"
                            src="images/collectio.png"
                            alt="#"
                            width="${!this.smallScreen ? 150 : 110}"
                            height="${!this.smallScreen ? 50 : 35}"
                        />
                        <img
                            class="img"
                            src="images/nets.png"
                            alt="#"
                            width="${!this.smallScreen ? 140 : 100}"
                            height="${!this.smallScreen ? 40 : 25}"
                        />
                        <img
                            style="margin-top:10px;"
                            class="img"
                            src="images/collectia.png"
                            alt="#"
                            width="${!this.smallScreen ? 150 : 110}"
                            height="${!this.smallScreen ? 60 : 45}"
                        />
                    </div>
                </div>
                <div class="customers-container" style="flex-direction:column; padding-right:0; width:100%;">
                    <h1 style="text-align:center">Noen av våre kunder</h1>
                    <div class="customers-img-container">
                        <img
                            class="img"
                            src="images/LogoRapida.png"
                            alt="#"
                            width="${!this.smallScreen ? 200 : 130}"
                            height="${!this.smallScreen ? 60 : 45}"
                        />

                        <img
                            class="img"
                            src="images/logo-optimal.png"
                            alt="#"
                            width="${!this.smallScreen ? 150 : 110}"
                            height="${!this.smallScreen ? 60 : 45}"
                        />
                        <img
                            class="img"
                            src="images/Crossfitgroruddalen.png"
                            alt="#"
                            width="${!this.smallScreen ? 200 : 130}"
                            height="${!this.smallScreen ? 60 : 45}"
                        />

                        <img
                            class="img"
                            src="images/synergi.png"
                            alt="#"
                            width="${!this.smallScreen ? 200 : 130}"
                            height="${!this.smallScreen ? 60 : 45}"
                        />

                        <img
                            class="img"
                            src="images/robust.png"
                            alt="#"
                            width="${!this.smallScreen ? 200 : 130}"
                            height="${!this.smallScreen ? 60 : 45}"
                        />
                    </div>
                </div>

                <div class="second-container" style=" flex-direction: column;  padding-left:0;">
                    <h1 style="text-align:center">Kontakt oss</h1>
                    <div class="map-container">
                        <div class="map">
                            <google-map coordinates="66.3131328,14.127023"></google-map>
                        </div>
                        <div class="map-text-container">
                            <span class="map-span">Besøksadresse</span>
                            <p class="map-text">
                                Midtre gate 5 <br />
                                8624 Mo i Rana
                            </p>

                            <span class="map-span">Fakturaadresse</span>
                            <p class="map-text">Postboks 413 <br />8624 Mo i Rana</p>
                        </div>
                    </div>
                </div>
            </div>
            <boost-website-footer></boost-website-footer>
        `;
    }
    animateText() {
        var _a;
        const animationText = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.animation-text');
        if (!animationText) {
            return;
        }
        gsap.from(animationText, {
            scrollTrigger: {
                trigger: '.animation-text',
                toggleActions: 'play pause resume reset',
                scrub: 1,
                start: 'top 100',
                end: 'bottom 3500',
            },
            x: -60,
            opacity: 0,
        });
    }
    animateText2() {
        var _a;
        const animationText = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.animation-text2');
        if (!animationText) {
            return;
        }
        gsap.from(animationText, {
            scrollTrigger: {
                trigger: '.animation-text2',
                toggleActions: 'play pause resume reset',
                scrub: 1,
                start: 'top -550',
                end: 'bottom 2600',
            },
            x: -60,
            opacity: 0,
        });
    }
    heroImageAnimation() {
        var _a;
        const animationHeroImage = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.hero-image');
        if (!animationHeroImage) {
            return;
        }
        gsap.fromTo(animationHeroImage, { duration: 1, rotation: 3, ease: 'bounce', x: 0 }, { duration: 1, rotation: 0, ease: 'bounce', x: -100 });
    }
    connectedCallback() {
        super.connectedCallback();
        this.updateComplete.then(() => {
            this.heroImageAnimation();
            this.animateText();
            this.animateText2();
        });
    }
    renderIcons(title) {
        if (title === 'booking') {
            return bookingIcon;
        }
        if (title === 'checkfulldrift') {
            return checkFullDrift;
        }
        if (title === 'communication') {
            return communicationIcon;
        }
        if (title === 'computer') {
            return computerIcon;
        }
        if (title === 'phone') {
            return phoneIcon;
        }
        if (title === 'wallet') {
            return walletIcon;
        }
    }
};
BoostWebsiteFrontPage._deps = [
    BoostWebsiteHeader,
    BoostWebsiteFooter,
    BoostWebsiteInformation,
    GoogleMap,
    BoostWebsiteImage,
    BoostWebsiteImageDevice,
    BoostWebsiteImageSystem,
    BoostImageSystem2,
    BoostWebsiteImageLocation,
    BoostWebsiteImageWeb,
];
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteFrontPage.prototype, "smallScreen", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], BoostWebsiteFrontPage.prototype, "modules", void 0);
BoostWebsiteFrontPage = __decorate([
    customElement(BoostWebsiteFrontPage.is)
], BoostWebsiteFrontPage);
export { BoostWebsiteFrontPage };
