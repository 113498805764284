var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostWebsiteImageSystem = class BoostWebsiteImageSystem extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-image-system';
    }
    static get styles() {
        return css `
            #smallscreen-image {
                opacity: 1;
            }
            @media screen and (min-width: 100px) and (max-width: 599px) {
                #smallscreen-image {
                    position: absolute;
                    opacity: 0.6;
                    -webkit-transform: translate(180px, 245px);
                    transform: translate(180px, 245px);
                }
            }

            @media screen and (min-width: 600px) and (max-width: 1199px) {
                #smallscreen-image {
                    position: absolute;
                    opacity: 1;
                    -webkit-transform: translate(386px, 159px);
                    transform: translate(386px, 159px);
                    width: 280px;
                    height: 200px;
                }
            }

            @media screen and (min-width: 1200px) {
                #smallscreen-image {
                    opacity: 1;
                    -webkit-transform: translate(110px, 45px);
                    transform: translate(110px, 45px);
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                width=${!this.smallScreen ? '500' : '180'}
                height=${!this.smallScreen ? '400' : '150'}
                viewBox="0 0 1100 506.53726"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                id="smallscreen-image"
            >
                <path
                    d="M967.08387,558.39253a10.05576,10.05576,0,0,1,2.72838-15.176l-6.58212-35.12342,17.12013,7.19268,3.62579,32.25108a10.11027,10.11027,0,0,1-16.89218,10.85568Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#ffb6b6"
                />
                <rect
                    id="f38b076b-59d8-4a3a-9764-ba710fdc09c8"
                    data-name="Rectangle 62"
                    x="61.13751"
                    y="20.60577"
                    width="751.60569"
                    height="356.64993"
                    fill="#e6e6e6"
                />
                <rect
                    id="a3e272cc-df3f-4d80-b321-62d216886f20"
                    data-name="Rectangle 75"
                    x="82.62993"
                    y="51.18913"
                    width="708.62191"
                    height="297.56419"
                    fill="#fff"
                />
                <rect
                    id="e835d13b-e721-4725-9202-bbf482b4bd3b"
                    data-name="Rectangle 80"
                    x="60.81703"
                    width="751.60569"
                    height="31.93044"
                    fill="#28e04d"
                />
                <circle
                    id="be4979c6-1dab-43b6-a6ee-71cd46d62419"
                    data-name="Ellipse 90"
                    cx="84.54532"
                    cy="14.76931"
                    r="5.91819"
                    fill="#fff"
                />
                <circle
                    id="b0ea5047-583b-4910-b1a7-8a4b76ab40b8"
                    data-name="Ellipse 91"
                    cx="107.00881"
                    cy="14.76931"
                    r="5.91819"
                    fill="#fff"
                />
                <circle
                    id="f37d9a3e-ad35-4554-bb71-f31101cbe222"
                    data-name="Ellipse 92"
                    cx="129.47335"
                    cy="14.76931"
                    r="5.91819"
                    fill="#fff"
                />
                <path
                    id="bb164f67-fbc5-46a9-bc53-51bf316e72eb-507"
                    data-name="Path 142"
                    d="M237.73441,374.36492a3.10552,3.10552,0,0,0,0,6.211h170.4059a3.10552,3.10552,0,0,0,0-6.211Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e6e6e6"
                />
                <path
                    id="a443cdab-5007-48b4-8b70-6d9701024bb2-508"
                    data-name="Path 143"
                    d="M237.73441,392.99975a3.10552,3.10552,0,0,0,0,6.211h86.99566a3.10552,3.10552,0,0,0,0-6.211Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e6e6e6"
                />
                <path
                    id="f7d6bd93-8cee-4d05-900c-0964279c7a84-509"
                    data-name="Path 154"
                    d="M408.35461,428.18126H343.62336a3.16582,3.16582,0,0,1-3.16228-3.16228V414.6477a3.16583,3.16583,0,0,1,3.16228-3.16228h64.73125a3.16583,3.16583,0,0,1,3.16228,3.16228V425.019A3.16582,3.16582,0,0,1,408.35461,428.18126Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#28e04d"
                />
                <path
                    id="b9375ed9-a91c-4567-8040-b14da9424350-510"
                    data-name="Path 154"
                    d="M408.35461,320.18126H343.62336a3.16582,3.16582,0,0,1-3.16228-3.16228V306.6477a3.16583,3.16583,0,0,1,3.16228-3.16228h64.73125a3.16583,3.16583,0,0,1,3.16228,3.16228V317.019A3.16582,3.16582,0,0,1,408.35461,320.18126Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#28e04d"
                />
                <path
                    id="e51c2c64-3ae5-421d-97d2-6993e1b4a0e1-511"
                    data-name="Path 142"
                    d="M522.44969,234.96153a4.0522,4.0522,0,0,0,0,8.10439H861.36133a4.0522,4.0522,0,0,0,0-8.10439Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e6e6e6"
                />
                <path
                    id="bb6ebd78-ef21-4bf3-8138-c36b4f5ae8d3-512"
                    data-name="Path 143"
                    d="M522.44969,259.277a4.0522,4.0522,0,0,0,0,8.10439H752.52438a4.0522,4.0522,0,0,0,0-8.10439Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e6e6e6"
                />
                <path
                    id="b0279cb1-e051-4fdd-8ff4-a6bdaa244cb4-513"
                    data-name="Path 142"
                    d="M522.44969,283.04229a4.0522,4.0522,0,0,0,0,8.10439H861.36133a4.0522,4.0522,0,0,0,0-8.10439Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e6e6e6"
                />
                <path
                    id="b8fe22d0-d29e-4fd2-8057-acce26acd380-514"
                    data-name="Path 143"
                    d="M522.44969,307.35774a4.0522,4.0522,0,0,0,0,8.10439H752.52438a4.0522,4.0522,0,0,0,0-8.10439Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e6e6e6"
                />
                <path
                    id="e1b0698e-7706-4223-aefd-57a6dfde5692-515"
                    data-name="Path 154"
                    d="M862.35461,359.94762H797.62336a3.16583,3.16583,0,0,1-3.16228-3.16228V346.41406a3.16583,3.16583,0,0,1,3.16228-3.16228h64.73125a3.16583,3.16583,0,0,1,3.16228,3.16228v10.37128A3.16583,3.16583,0,0,1,862.35461,359.94762Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#28e04d"
                />
                <path
                    id="f8f2501b-24d6-4bc5-9452-5f6747352c8d-516"
                    data-name="Path 155"
                    d="M311.12694,239.351c-1.1501.00364-2.08062,1.49718-2.07834,3.3359.00228,1.83359.93146,3.31911,2.07834,3.32272h98.17952c1.1501-.00364,2.08061-1.49718,2.07833-3.3359-.00228-1.83358-.93145-3.3191-2.07833-3.32272Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e4e4e4"
                />
                <path
                    id="bf240ba4-7ac6-4616-9791-82be96e2dade-517"
                    data-name="Path 156"
                    d="M311.12694,258.64339c-1.1501.00365-2.08062,1.49719-2.07834,3.3359.00228,1.83359.93146,3.31911,2.07834,3.32273h98.17952c1.1501-.00365,2.08061-1.49718,2.07833-3.3359-.00228-1.83359-.93145-3.31911-2.07833-3.32273Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e4e4e4"
                />
                <path
                    id="a4452d36-433f-4f97-99ed-f04c2decb731-518"
                    data-name="Path 157"
                    d="M311.12694,277.70246c-1.1501.00364-2.08062,1.49718-2.07834,3.3359.00228,1.83358.93146,3.3191,2.07834,3.32272h42.24679c1.1501-.00365,2.08062-1.49718,2.07834-3.3359-.00228-1.83359-.93146-3.31911-2.07834-3.32272Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#e4e4e4"
                />
                <circle cx="147.61462" cy="129.87205" r="26.64189" fill="#e4e4e4" />
                <path
                    d="M812.93462,354.67141a10.05576,10.05576,0,0,0,10.45346,11.33494l13.31553,33.16136,10.57664-15.2633-14.26617-29.15056a10.11027,10.11027,0,0,0-20.07946-.08244Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#ffb6b6"
                />
                <polygon
                    points="849.397 621.765 837.138 621.765 831.305 574.477 849.4 574.478 849.397 621.765"
                    fill="#ffb6b6"
                />
                <path
                    d="M966.18627,765.38074l-39.53076-.00147v-.5A15.38731,15.38731,0,0,1,942.042,749.493h.001l24.144.001Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#2f2e41"
                />
                <polygon
                    points="809.279 621.376 797.591 617.675 806.302 570.832 823.552 576.294 809.279 621.376"
                    fill="#ffb6b6"
                />
                <path
                    d="M922.33567,765.38074l-37.6871-11.93162.1509-.47669A15.38729,15.38729,0,0,1,904.112,742.9472l.00093.00029,23.018,7.28751Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#2f2e41"
                />
                <path
                    d="M908.42109,522.38068s-18.19032,28.56223-11.76248,54.05435,13.42783,74.49213,13.42783,74.49213l34.85215,82.94338,25.98-1.05987-25.8-97.793,1.02014-65.38582s17.932-31.24936,14.43984-40.977S908.42109,522.38068,908.42109,522.38068Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#2f2e41"
                />
                <path
                    d="M967.84857,532.59252s2.23787,28.33464-4.76213,40.33464-14.45784,57.67343-14.45784,57.67343l-11.89805,91.34626-24.64411-8.01969L933.101,611.58464l16.517-80.50394Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#2f2e41"
                />
                <path
                    d="M976.58644,395.42716l-19.22786-13.2165-29.26032-.84988-10.18963,9.98984-15.55,7.94055-1.44007,129.36367s46.56525,22.31937,71.61656,11.54584l12.05131-100.77352C989.58644,419.42716,976.58644,395.42716,976.58644,395.42716Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#6c63ff"
                />
                <path
                    d="M906.26415,433.86141H854.97582l-33.69628-58.00195,21.91064-8.67871,18.55566,33.00781,26.85718-2.43067,16.76929-.811a18.4681,18.4681,0,0,1,19.22241,20.69678h0A18.48975,18.48975,0,0,1,906.26415,433.86141Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#6c63ff"
                />
                <circle cx="831.45187" cy="213.60642" r="25.37" fill="#ffb6b6" />
                <path
                    d="M975.68286,339.56569c-.77906-5.56976-4.55127-10.85827-9.916-12.54565-2.1311-6.1886-7.77588-10.74475-14.01245-12.73083a27.3719,27.3719,0,0,0-31.467,11.47662c-.4773.75043-1.94483,2.594-1.90381,3.4895.052,1.13733,1.792,2.31165,2.666,3.02869a39.08567,39.08567,0,0,0,6.97046,4.30224c9.12281,4.72962,6.08594,12.16248,5.96216,20.58533-.06006,4.09314,1.3064,7.588,4.79663,9.926,5.03711,3.37421,12.24585,2.0318,17.72657.67762,6.219-1.53675,11.3894-7,14.936-12.26764C974.58251,350.84243,976.46215,345.13546,975.68286,339.56569Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#2f2e41"
                />
                <polygon
                    points="862.197 296.725 872.069 405.008 840.352 403.307 862.197 296.725"
                    fill="#6c63ff"
                />
                <path
                    d="M1085.147,768.26863H696.853a1.19069,1.19069,0,0,1,0-2.38137h388.294a1.19068,1.19068,0,0,1,0,2.38137Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#cacaca"
                />
                <polygon
                    points="84.089 624.666 93.598 624.666 98.122 587.987 84.087 587.987 84.089 624.666"
                    fill="#9e616a"
                />
                <path
                    d="M195.32559,753.29279,214.053,753.292h.00076a11.93522,11.93522,0,0,1,11.93457,11.93438v.38783l-30.66217.00114Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#2f2e41"
                />
                <polygon
                    points="171.738 624.666 181.247 624.666 185.771 587.987 171.736 587.987 171.738 624.666"
                    fill="#9e616a"
                />
                <path
                    d="M282.97442,753.29279l18.72741-.00076h.00076a11.93524,11.93524,0,0,1,11.93457,11.93438v.38783l-30.66217.00114Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#2f2e41"
                />
                <polygon
                    points="160.256 329.996 166.461 369.554 107.512 370.33 115.268 332.323 160.256 329.996"
                    fill="#9e616a"
                />
                <path
                    d="M278.4129,491.62686l18.77521,49.99293,2.327,207.17884H283.61418l-38.007-166.457-34.9044,162.11154H195.1897l10.85915-182.27853s-3.20226-36.58541,13.1861-58.174l3.98907-11.52474Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#3d325e"
                />
                <path
                    d="M197.87793,530.5989a11.63672,11.63672,0,0,0,7.45575-16.21121l25.07809-81.76061-18.44591-3.6565-22.93636,80.1425a11.69979,11.69979,0,0,0,8.84843,21.48582Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#9e616a"
                />
                <path
                    d="M230.953,483.57526a5.28456,5.28456,0,0,1-1.025-.10116h0a5.30084,5.30084,0,0,1-4.24613-4.8025c-.58311-7.98356-3.47232-21.88028-8.5874-41.305a32.6914,32.6914,0,0,1,51.62121-34.13692,32.2691,32.2691,0,0,1,12.45024,22.06768c2.54221,22.38772-2.25054,44.86542-4.42895,53.49034a5.31242,5.31242,0,0,1-5.03549,4.00194L231.066,483.574C231.02828,483.57484,230.99076,483.57526,230.953,483.57526Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#1f477d"
                />
                <path
                    d="M344.48105,411.7567a11.402,11.402,0,0,0-.59972,1.69231l-50.86412,17.284-9.82844-8.58615-14.89671,13.13921,15.92214,16.891a8.66578,8.66578,0,0,0,10.24779,1.77317l54.42773-27.80157a11.3707,11.3707,0,1,0-4.40867-14.392Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#9e616a"
                />
                <path
                    d="M291.24405,431.67773l-18.54216,16.19516a4.87454,4.87454,0,0,1-7.308-1.03711l-13.26272-20.65256a13.53749,13.53749,0,0,1,20.444-17.75089l18.62427,15.86433a4.87453,4.87453,0,0,1,.04465,7.38107Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#1f477d"
                />
                <path
                    d="M227.48214,453.56118l-22.91416-9.002a4.87455,4.87455,0,0,1-2.4445-6.96468l12.22567-21.28289a13.53748,13.53748,0,0,1,25.17085,9.9738l-5.50642,23.83736a4.87453,4.87453,0,0,1-6.53144,3.43841Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#1f477d"
                />
                <circle cx="130.80563" cy="234.72924" r="24.92493" fill="#9e616a" />
                <path
                    d="M503.147,768.26863H114.853a1.19069,1.19069,0,0,1,0-2.38137H503.147a1.19069,1.19069,0,0,1,0,2.38137Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#cacaca"
                />
                <path
                    d="M261.61426,356.44092q8.02147-.04514,16.043-.09033c.38037-3.64466-1.2417-7.27069-3.605-10.07093a41.05788,41.05788,0,0,0-8.4458-6.98224c-3.06739-2.105-6.24512-4.25787-9.89161-4.99359-3.05664-.61657-6.21582-.18872-9.30371.24066q-6.14282.85419-12.28515,1.70832c-4.49512.62506-9.19825,1.332-12.84912,4.02789-4.9375,3.64563-6.82618,10.03369-8.4126,15.96234l-8.78174,32.81574,51.8706,5.44085a14.26147,14.26147,0,0,0,5.65284-.16424,4.85888,4.85888,0,0,0,3.61474-3.95233c.14942-2.11548-1.52881-3.847-2.8916-5.47174a25.56243,25.56243,0,0,1-2.20459-28.90564Z"
                    transform="translate(-113.66231 -131.73137)"
                    fill="#2f2e41"
                />
            </svg>
        `;
    }
};
BoostWebsiteImageSystem._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteImageSystem.prototype, "smallScreen", void 0);
BoostWebsiteImageSystem = __decorate([
    customElement(BoostWebsiteImageSystem.is)
], BoostWebsiteImageSystem);
export { BoostWebsiteImageSystem };
