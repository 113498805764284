var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BoostWebsiteImageSuccess } from '../../website-images/BoostWebsiteImageSuccess';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
let BoostWebsiteContactRequestSuccess = class BoostWebsiteContactRequestSuccess extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-contact-request-success';
    }
    static get styles() {
        return css `
            h1 {
                font-size: 28px;
                color: #132c4f;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
            }
            span {
                color: #8093af;
                font-size: 20px;
                display: flex;
                justify-content: center;
                text-align: center;
            }
            .container {
                border-radius: 70px;
                height: 450px;
                margin-right: auto;
                width: 30%;
                margin-left: auto;
                margin-top: 40px;
                background-color: #f8f8f8;
                box-shadow: 2px 5px 5px -7px gray;
            }
            @media screen and (max-width: 1000px) {
                .container {
                    width: 50%;
                    height: 160px;
                    border-radius: 10px;
                    margin-top: 15%;
                    margin-bottom: 10%;
                    overflow: hidden;
                }
                h1 {
                    font-size: 20px;
                }
                span {
                    font-size: 18px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div
                style=" overflow: hidden; width: 100%; min-height: 100vh; background: linear-gradient(to left, rgba(245,245,245,0.9) 1%, rgba(245,245,245,0.99) 90%),url('images/hexagonimage.png') ; "
            >
                <boost-website-header
                    @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
                ></boost-website-header>
                <div class="container">
                    <boost-website-image-success
                        .smallScreen="${this.smallScreen}"
                    ></boost-website-image-success>
                </div>
                <h1>Takk for din henvendelse</h1>
                <span>Du hører fra oss så snart vi har gått i gjennom din forespørsel </span>
            </div>
        `;
    }
};
BoostWebsiteContactRequestSuccess._deps = [BoostWebsiteImageSuccess, BoostWebsiteHeader];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteContactRequestSuccess.prototype, "smallScreen", void 0);
BoostWebsiteContactRequestSuccess = __decorate([
    customElement(BoostWebsiteContactRequestSuccess.is)
], BoostWebsiteContactRequestSuccess);
export { BoostWebsiteContactRequestSuccess };
