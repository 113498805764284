import { html } from 'lit';
export const checkFullDrift = html `<svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    width="24"
    height="24"
    color="#000000"
>
    <defs>
        <style>
            .cls-637647fac3a86d32eae6f21a-1 {
                fill: none;
                stroke: currentColor;
                stroke-miterlimit: 10;
            }
        </style>
    </defs>
    <polyline class="cls-637647fac3a86d32eae6f21a-1" points="13.91 12.96 9.14 17.73 6.75 15.34"></polyline>
    <path
        class="cls-637647fac3a86d32eae6f21a-1"
        d="M20.59,22.5H3.41A1.92,1.92,0,0,1,1.5,20.59V7.23H18.68V20.59A1.92,1.92,0,0,0,20.59,22.5Z"
    ></path>
    <path
        class="cls-637647fac3a86d32eae6f21a-1"
        d="M22.5,3.41V20.59a1.91,1.91,0,0,1-3.82,0V7.23H5.32V1.5h9.54l1.87,1.91Z"
    ></path>
</svg>`;
