var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
let BoostWebsiteFooter = class BoostWebsiteFooter extends LitElement {
    static get is() {
        return 'boost-website-footer';
    }
    /** @override */ render() {
        return html `
            <div
                style="display:flex; justify-content:center; font-size:18px;background-color: #f1f1f1;padding: 10px; text-align:center"
            >
                <span style="color:#677fa0; font-size:20px">Skysoft AS — Org. nummer 999 290 396</span>
            </div>
        `;
    }
};
BoostWebsiteFooter._deps = [];
BoostWebsiteFooter = __decorate([
    customElement(BoostWebsiteFooter.is)
], BoostWebsiteFooter);
export { BoostWebsiteFooter };
