var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { TextField } from '@vaadin/text-field';
import { TextArea } from '@vaadin/text-area';
import { EmailField } from '@vaadin/email-field';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
import { isErrorHTTP, isSuccessHTTP } from 'base-api/APIBase';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { Router } from '@vaadin/router';
import { WebAPI } from 'webpage-api/cerum/webpage/web/WebAPI';
import { BoostImageContact } from '../../website-images/BoostImageContact';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
let BoostWebsiteContact = class BoostWebsiteContact extends LitElement {
    constructor() {
        super(...arguments);
        this.input = { name: '', message: '', phone: '', mail: '' };
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-contact';
    }
    static get styles() {
        return css `
            .info-btn {
                height: 35px;
                width: 190px;
                border: none;
                border-radius: 6px;
                background-color: #122c4f;
                color: #eeeff1;
                font-size: 18px;
                cursor: pointer;
            }

            a {
                text-decoration: none;
            }

            h1 {
                font-size: 30px;
                color: #132c4f;
                margin-bottom: 10px;
            }

            span {
                color: #8093af;
                font-size: 18px;
            }

            .contact-text {
                color: #132c4f;
                font-size: 18px;
            }

            .line {
                width: 60%;
                color: #677fa0;
                border-top: 1px;
            }

            .contact-form {
                background-color: #fdfdfd;
                width: 30%;
                border-radius: 6px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                margin-bottom: 25px;
                box-shadow: 1px 2px 5px -2px gray;
            }

            .information-container {
                width: 40%;
                padding-top: 20px;
            }

            .contact-text-form-container {
                display: flex;
                flex-direction: column;
                text-align: center;
            }

            @media screen and (max-width: 1000px) {
                .main-header {
                    font-size: 22px;
                    color: #132c4f;
                }

                .header {
                    font-size: 22px;
                    color: #132c4f;
                }

                .smallscreen-span {
                    font-size: 15px;
                    width: 80%;
                }

                .contact-text {
                    font-size: 18px;
                    color: #132c4f;
                }

                hr {
                    width: 100%;
                }

                .line {
                    width: 90%;
                    border-top: 1px solid#132c4f;
                }

                .contact-form {
                    width: 80%;
                }

                .information-container {
                    padding-left: 0;
                    width: 85%;
                    padding-top: 0;
                }

                span {
                    font-size: 18px;
                }

                .image-container {
                    padding-right: 0;
                }

                .contact-text-form-container {
                    width: 80%;
                    text-align: left;
                }
            }
        `;
    }
    get validationFields() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.validate');
    }
    /** @override */ render() {
        return html `
            <div
                style=" overflow: hidden; width: 100%; min-height: 100vh; background: linear-gradient(to left, rgba(245,245,245,0.9) 1%, rgba(245,245,245,0.99) 90%),url('images/hexagonimage.png') ; "
            >
                <boost-website-header
                    @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
                ></boost-website-header>
                <div style="display: flex;justify-content: center;flex-direction: row; width:100%;">
                    <div class="information-container">
                        <h1 class="main-header">Vi er tilgjengelig mandag til fredag 08.00 - 16.00</h1>
                        <span
                            >Vår kontakttelefon er åpen fra 08.00-16.00 mandag til fredag. Skulle det være noe
                            i mellomtiden er vi tilgjengelig på e-post. Ikke nøl med å ta kontakt dersom du
                            lurer på noe.</span
                        >
                        <p class="contact-text">
                            Sentralbord: <span href="+4775198115" style="color:#677fa0">+47 75 19 81 15</span>
                        </p>
                        <p class="contact-text">
                            Support:
                            <a href="mailto:support@boostsystem.no" style="color:#677fa0"
                                >support@boostsystem.no</a
                            >
                        </p>
                        <p class="contact-text">
                            Andre henvendelser:
                            <a href="mailto:post@boostsystem.no" style="color:#677fa0">post@boostsystem.no</a>
                        </p>
                    </div>
                    ${!this.smallScreen
            ? html `<boost-image-contact style="width:25%;"></boost-image-contact>`
            : html ``}
                </div>

                <hr class="line" />
                <div
                    style="display: flex;flex-direction: column; align-items: center; padding-top:20px; padding-bottom:25px"
                >
                    <div class="contact-text-form-container">
                        <h1 class="header">Ta kontakt for å få tilbud, eller hvis du har noen spørsmål:</h1>
                        <p class="contact-text" style="color:#8193af; padding-bottom:20px">
                            Skjema gjelder ikke for medlemmer på treningssenter. Har du spørsmål vedrørende
                            ditt medlemskap, <br />
                            ta kontakt direkte med treningsenteret du tilhører.
                        </p>
                    </div>
                    ${this._renderForm()}
                </div>
            </div>
            <boost-website-footer></boost-website-footer>
        `;
    }
    _renderForm() {
        return html `<div class="contact-form">
            <vaadin-text-field
                class="validate"
                label="Ditt navn"
                error-message="Navn må fylles ut"
                value="${this.input.name}"
                required
                @value-changed="${(event) => (this.input.name = event.detail.value)}"
            ></vaadin-text-field>
            <vaadin-email-field
                class="validate"
                label="Din e-postadresse"
                value="${this.input.mail}"
                required
                error-message="E-postadresse må fylles ut"
                @value-changed="${(event) => (this.input.mail = event.detail.value)}"
            ></vaadin-email-field>
            <vaadin-text-field
                class="validate"
                value="${this.input.subject || ''}"
                label="Emne"
                required
                error-message="Emne må fylles ut"
                @value-changed="${(event) => (this.input.subject = event.detail.value)}"
            ></vaadin-text-field>
            <vaadin-text-area
                style="padding-bottom:25px"
                class="validate"
                label="Din henvendelse"
                value="${this.input.message}"
                required
                error-message="Må fylles ut"
                @value-changed="${(event) => (this.input.message = event.detail.value)}"
            ></vaadin-text-area>
            <div style="display:flex; justify-content:center">
                <button class="info-btn" @click="${() => this._sendRequest()}">Send inn</button>
            </div>
        </div> `;
    }
    _validateFields() {
        let hasInvalidFields = false;
        this.validationFields.forEach((validationField) => {
            if (!validationField.validate()) {
                hasInvalidFields = true;
            }
        });
        return !hasInvalidFields;
    }
    _sendRequest() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._validateFields()) {
                return;
            }
            const webContact = yield WebAPI.postV1WebContact({ receiver: 'boost' }, this.input);
            if (isSuccessHTTP(webContact)) {
                SnackBar.push({ title: 'Din forespørsel er sendt', type: 'success' });
                Router.go('/success');
            }
            else if (isErrorHTTP(webContact)) {
                SnackBar.pushError(webContact.error);
            }
        });
    }
};
BoostWebsiteContact._deps = [
    BoostWebsiteHeader,
    BoostWebsiteFooter,
    TextField,
    TextArea,
    EmailField,
    BoostImageContact,
];
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostWebsiteContact.prototype, "input", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteContact.prototype, "smallScreen", void 0);
BoostWebsiteContact = __decorate([
    customElement(BoostWebsiteContact.is)
], BoostWebsiteContact);
export { BoostWebsiteContact };
