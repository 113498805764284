import { html } from 'lit';
export const computerIcon = html `
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        width="24"
        height="24"
        color="#000000"
    >
        <defs>
            <style>
                .cls-637a50fc15c5759009400671-1 {
                    fill: none;
                    stroke: currentColor;
                    stroke-miterlimit: 10;
                }
            </style>
        </defs>
        <path
            class="cls-637a50fc15c5759009400671-1"
            d="M5.32,1.5H18.68a1.91,1.91,0,0,1,1.91,1.91V14.86a0,0,0,0,1,0,0H3.41a0,0,0,0,1,0,0V3.41A1.91,1.91,0,0,1,5.32,1.5Z"
        ></path>
        <path
            class="cls-637a50fc15c5759009400671-1"
            d="M20.59,14.86H3.41L1.58,20.34a1.82,1.82,0,0,0-.08.52h0A1.65,1.65,0,0,0,3.14,22.5H20.86a1.65,1.65,0,0,0,1.64-1.64h0a1.82,1.82,0,0,0-.08-.52Z"
        ></path>
        <line class="cls-637a50fc15c5759009400671-1" x1="10.09" y1="19.64" x2="13.91" y2="19.64"></line>
    </svg>
`;
