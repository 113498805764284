var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BoostImageProfileIcon } from '../../website-images/BoostImageProfileIcon';
import { BoostWebsiteMailIcon } from '../../website-images/BoostWebsiteMailIcon';
import { BoostWebsitePhoneIcon } from '../../website-images/BoostWebsitePhoneIcon';
import { BoostImageAboutUs } from '../../website-images/BoostImageAboutUs';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
let BoostWebsiteAboutUs = class BoostWebsiteAboutUs extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-about-us';
    }
    static get styles() {
        return css `
            p {
                color: #677fa0;
                font-size: 18px;
            }

            h1 {
                font-size: 30px;
                color: #132c4f;
                margin-bottom: 10px;
                padding-top: 20px;
            }

            .text-container {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
            }

            .contact-information {
                display: flex;
                justify-content: center;
            }

            .contact-text {
                color: #132c4f;
                font-size: 18px;
                text-decoration: none;
            }

            .header-text {
                color: #132c4f;
                font-size: 28px;
                padding-top: 25px;
            }

            .container {
                display: flex;
                flex-direction: column;
                width: 40%;
            }

            boost-image-profile-picture {
                position: absolute;
            }

            @media screen and (max-width: 1000px) {
                p {
                    width: 90%;
                    font-size: 18px;
                    padding: 20px;
                }

                h1 {
                    padding-left: 20px;
                    padding-top: 0px;
                }

                .text-container {
                    text-align: center;
                }

                span {
                    width: 80%;
                }

                .contact-text {
                    font-size: 18px;
                    color: #132c4f;
                }

                .header-text {
                    font-size: 20px;
                }

                .container {
                    width: 100%;
                }

                #about-text {
                    padding-left: 20px;
                    padding-top: 0px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div
                style=" overflow: hidden; width: 100%; min-height: 100vh; background: linear-gradient(to left, rgba(245,245,245,0.9) 1%, rgba(245,245,245,0.99) 90%),url('images/hexagonimage.png') ; "
            >
                <boost-website-header
                    @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
                ></boost-website-header>
                <div style=" width: 100%;display: flex;  justify-content: center;">
                    <div class="container">
                        <h1>Om oss</h1>
                        <p id="about-text">
                            Boost system har markedets mest moderne system og er en skreddersydd løsning for
                            ditt treningssenter. Alle funksjoner er samlet på ett sted og vil gjøre driften av
                            ditt senter tidsbesparende og effektivisert. Vi tilbyr god opplæring, oppfølging
                            og hyggelig support.
                            <br />
                            <br />
                            Medlemssystemet er en web-basert alt-i-ett løsning, noe som gir en unik
                            tilgjengelighet og forutsigbare driftskostnader.
                            <br />
                            <br />
                            Vi har mange fornøyde kunder over hele landet, der vi fokuserer på å følge opp
                            våre kunder, og legge til rette for en enklere hverdag. Vi er stadig i utvikling
                            og vektlegger å kunne gi våre kunder gode og moderne løsninger.
                        </p>
                    </div>

                    ${!this.smallScreen
            ? html ` <boost-image-about-us style="width:25%;"></boost-image-about-us> `
            : html ``}
                </div>
            </div>
            <boost-website-footer></boost-website-footer>
        `;
    }
};
BoostWebsiteAboutUs._deps = [
    BoostWebsiteHeader,
    BoostImageProfileIcon,
    BoostWebsiteMailIcon,
    BoostWebsitePhoneIcon,
    BoostImageAboutUs,
    BoostWebsiteFooter,
];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteAboutUs.prototype, "smallScreen", void 0);
BoostWebsiteAboutUs = __decorate([
    customElement(BoostWebsiteAboutUs.is)
], BoostWebsiteAboutUs);
export { BoostWebsiteAboutUs };
