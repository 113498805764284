var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
import { router } from '../router/BoostWebsiteRouter';
import { BoostWebsiteAboutUs } from './BoostWebsiteAboutUs';
import { BoostWebsiteContact } from './BoostWebsiteContact';
import { BoostWebsiteFrontPage } from './BoostWebsiteFrontPage';
let FrontPage = class FrontPage extends LitElement {
    static get is() {
        return 'front-page';
    }
    get routerOutlet() {
        return this.shadowRoot && this.shadowRoot.getElementById('routerOutlet');
    }
    constructor() {
        super();
        this.updateComplete.then(() => {
            router.setOutlet(this.routerOutlet);
        });
    }
    /** @override */ render() {
        return html ` <div id="routerOutlet" style="overflow: hidden;"></div> `;
    }
};
FrontPage._deps = [
    BoostWebsiteFooter,
    BoostWebsiteFrontPage,
    BoostWebsiteContact,
    BoostWebsiteAboutUs,
];
FrontPage = __decorate([
    customElement(FrontPage.is),
    __metadata("design:paramtypes", [])
], FrontPage);
export { FrontPage };
