var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostWebsiteImageDevice = class BoostWebsiteImageDevice extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-image-device';
    }
    static get styles() {
        return css `
            @media screen and (min-width: 100px) and (max-width: 439px) {
                #smallscreen-image {
                    position: absolute;
                    opacity: 0.6;
                    -webkit-transform: translate(232px, 226px);
                    transform: translate(232px, 226px);
                }
            }
            @media screen and (min-width: 440px) and (max-width: 599px) {
                #smallscreen-image {
                    position: absolute;
                    opacity: 0.6;
                    -webkit-transform: translate(290px, 200px);
                    transform: translate(290px, 200px);
                }
            }

            @media screen and (min-width: 600px) and (max-width: 1199px) {
                #smallscreen-image {
                    position: absolute;
                    opacity: 1;
                    -webkit-transform: translate(336px, 214px);
                    transform: translate(336px, 214px);
                    width: 300px;
                    height: 170px;
                }
            }

            @media screen and (min-width: 1200px) {
                #smallscreen-image {
                    opacity: 1;
                    -webkit-transform: translate(30px, 38px);
                    transform: translate(30px, 38px);
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                width=${!this.smallScreen ? '370' : '140'}
                height=${!this.smallScreen ? '280' : '195'}
                viewBox="0 0 945 730.51882"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                id="smallscreen-image"
            >
                <path
                    d="M942.26611,727.00988a8.991,8.991,0,0,1-7.06351,4.78455c-.75994.07473-.54333,1.24.21166,1.1658a10.07514,10.07514,0,0,0,7.82586-5.27567.61233.61233,0,0,0-.14967-.82435.59542.59542,0,0,0-.82434.14967Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#fff"
                />
                <path
                    d="M606.96069,257.68784h-3.99878V148.14251a63.40184,63.40184,0,0,0-63.40179-63.40192H307.47339a63.40184,63.40184,0,0,0-63.402,63.40166V749.11672a63.40186,63.40186,0,0,0,63.40179,63.40192H539.55981a63.40187,63.40187,0,0,0,63.402-63.40168V335.66408h3.99884Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#3f3d56"
                />
                <path
                    d="M542.11816,101.23557h-30.295A22.49487,22.49487,0,0,1,490.996,132.2261H358.03656a22.49487,22.49487,0,0,1-20.82715-30.99057H308.91385A47.34779,47.34779,0,0,0,261.566,148.58326v600.0926a47.34778,47.34778,0,0,0,47.34777,47.34784H542.11816A47.3478,47.3478,0,0,0,589.466,748.67592h0V148.58332A47.34777,47.34777,0,0,0,542.11816,101.23557Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#fff"
                />
                <rect x="228.11606" y="177.70432" width="82.65982" height="7.67616" fill="#28e04d" />
                <rect x="228.11606" y="204.25859" width="144.06912" height="7.67616" fill="#e6e6e6" />
                <rect x="228.11606" y="230.81287" width="143.92807" height="7.67616" fill="#e6e6e6" />
                <rect x="149.57644" y="177.81828" width="61.4093" height="61.40932" fill="#28e04d" />
                <rect x="228.11606" y="338.90375" width="82.65982" height="7.67615" fill="#28e04d" />
                <rect x="228.11606" y="365.45804" width="144.06912" height="7.67615" fill="#e6e6e6" />
                <rect x="228.11606" y="392.0123" width="143.92807" height="7.67615" fill="#e6e6e6" />
                <rect x="149.57644" y="339.01773" width="61.4093" height="61.4093" fill="#28e04d" />
                <rect x="228.11606" y="500.10318" width="82.65982" height="7.67615" fill="#28e04d" />
                <rect x="228.11606" y="526.65747" width="144.06912" height="7.67615" fill="#e6e6e6" />
                <rect x="228.11606" y="553.21173" width="143.92807" height="7.67615" fill="#e6e6e6" />
                <rect x="149.57644" y="500.21716" width="61.4093" height="61.4093" fill="#28e04d" />
                <polygon
                    points="725.531 715.905 713.29 716.579 704.864 669.683 722.931 668.689 725.531 715.905"
                    fill="#ffb6b6"
                />
                <path
                    d="M876.46882,812.33929l-39.471,2.17354-.02752-.49924A15.38729,15.38729,0,0,1,851.487,797.8041l.001-.00006,24.10752-1.32744Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#2f2e41"
                />
                <polygon
                    points="468.885 527.295 467.301 515.138 513.439 503.242 515.777 521.184 468.885 527.295"
                    fill="#ffb6b6"
                />
                <path
                    d="M604.66766,616.67182l-5.10832-39.19932.49581-.06463a15.3873,15.3873,0,0,1,17.246,13.26856l.00013.001,3.1199,23.94162Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#2f2e41"
                />
                <path
                    d="M770.80054,535.20236a10.52605,10.52605,0,0,1,1.56249.55311l41.954-26.44521-.44854-12.03966L831.95,494.21884l2.86536,21.23668a8,8,0,0,1-4.68255,8.38175L778.56322,546.7276a10.4971,10.4971,0,1,1-7.76268-11.52524Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#ffb6b6"
                />
                <path
                    d="M812.40094,507.633a4.49518,4.49518,0,0,1-1.6449-3.36672l-.55876-22.57854a12.49741,12.49741,0,0,1,24.613-4.35151l7.10906,21.51458A4.5,4.5,0,0,1,838.417,504.696l-22.392,3.8912A4.49494,4.49494,0,0,1,812.40094,507.633Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#e4e4e4"
                />
                <circle cx="700.12018" cy="330.04261" r="30.44946" fill="#2f2e41" />
                <circle cx="710.77185" cy="297.9948" r="12.59979" fill="#2f2e41" />
                <path
                    d="M874.73389,375.91052a12.601,12.601,0,0,0-11.28729-12.53164,12.72977,12.72977,0,0,1,1.3125-.06812,12.59978,12.59978,0,0,1,0,25.19956,12.74944,12.74944,0,0,1-1.3125-.06812A12.60106,12.60106,0,0,0,874.73389,375.91052Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#2f2e41"
                />
                <circle cx="700.107" cy="343.89619" r="19.25398" fill="#ffb8b8" />
                <path
                    d="M879.31891,561.49783s-.91687,22.92139-4.58429,34.84058,2.75061,180.62079-.45837,184.74664-18.33716,2.75055-18.33716,2.75055l-14.03358-87.89286s-2.46984-93.18636-36.39355-95.47847-166.40948,10.54388-166.40948,10.54388l-2.75055-18.79553,71.0564-15.58655s65.0968-22.463,100.85424-16.50342S879.31891,561.49783,879.31891,561.49783Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#2f2e41"
                />
                <path
                    d="M875.19306,450.5582c3.66748-2.75055,12.836.91687,12.836.91687l10.08545,29.79785s-5.04272,25.672-9.627,39.88327-13.41572,77.03594-11.582,79.78655-29.67657-19.73238-48.47216-26.60885-22.92145-14.66973-22.92145-14.66973,20.62928-29.33942,13.75287-43.09226-1.3753-29.33942-1.3753-29.33942l-2.75055-15.58657,15.58655-8.71015S871.52558,453.30875,875.19306,450.5582Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#e4e4e4"
                />
                <ellipse cx="696.57807" cy="323.58656" rx="20.99963" ry="12.59979" fill="#2f2e41" />
                <path
                    d="M964.41425,458.57631a10.52681,10.52681,0,0,0-.56228,1.55921L916.80814,475.8316,907.7788,467.855l-13.81911,12.05347,14.61236,15.67437a8,8,0,0,0,9.45126,1.68926l50.38733-25.38721a10.4971,10.4971,0,1,0-3.99639-13.30862Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#ffb6b6"
                />
                <path
                    d="M915.16641,476.69464l-17.2,14.856a4.5,4.5,0,0,1-6.74113-.99474l-12.13808-19.1333a12.49741,12.49741,0,0,1,18.96363-16.28243l17.11209,14.74039a4.5,4.5,0,0,1,.00353,6.81412Z"
                    transform="translate(-147.16231 -84.74059)"
                    fill="#e4e4e4"
                />
            </svg>
        `;
    }
};
BoostWebsiteImageDevice._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteImageDevice.prototype, "smallScreen", void 0);
BoostWebsiteImageDevice = __decorate([
    customElement(BoostWebsiteImageDevice.is)
], BoostWebsiteImageDevice);
export { BoostWebsiteImageDevice };
