import { html } from 'lit';
export const phoneIcon = html `
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        width="24"
        height="24"
        color="#000000"
    >
        <defs>
            <style>
                .cls-6374f8d9b67f094e4896c667-1 {
                    fill: none;
                    stroke: currentColor;
                    stroke-miterlimit: 10;
                }
            </style>
        </defs>
        <rect
            class="cls-6374f8d9b67f094e4896c667-1"
            x="5.32"
            y="1.5"
            width="13.36"
            height="21"
            rx="1.91"
        ></rect>
        <path
            class="cls-6374f8d9b67f094e4896c667-1"
            d="M13.91,2.45H10.09a.94.94,0,0,1-.95-1h5.72A.94.94,0,0,1,13.91,2.45Z"
        ></path>
        <line class="cls-6374f8d9b67f094e4896c667-1" x1="11.05" y1="19.64" x2="12.95" y2="19.64"></line>
    </svg>
`;
